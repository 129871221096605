import { createContext, Dispatch, SetStateAction, useState } from "react"

export type HeaderContextProps = {
  children?: JSX.Element
  hamburgerMenuColours: ("primary" | "secondary" | "tertiary" | "accent" | "copy" | "light" | undefined)[]
  hasHero: boolean
  setHasHero: Dispatch<SetStateAction<boolean>>
  setHamburgerMenuColours: Dispatch<SetStateAction<("primary" | "secondary" | "tertiary" | "accent" | "copy" | "light" | undefined)[]>>
}

export const HeaderContext = createContext<HeaderContextProps>({
  hamburgerMenuColours: [],
  hasHero: false,
  setHasHero: () => false,
  setHamburgerMenuColours: () => false,
})

export const HeaderProvider: FC<HeaderContextProps> = ({ children }: Partial<HeaderContextProps>) => {
  const [hamburgerMenuColours, setHamburgerMenuColours] = useState<("primary" | "secondary" | "tertiary" | "accent" | "copy" | "light" | undefined)[]>([])
  const [hasHero, setHasHero] = useState<boolean>(false)

  const values = {
    hamburgerMenuColours,
    hasHero,
    setHasHero,
    setHamburgerMenuColours,
  }

  return <HeaderContext.Provider value={{ ...values }}>{children}</HeaderContext.Provider>
}
