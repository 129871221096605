import emotionReset from "emotion-reset"
import { css } from "@emotion/core"
import tw from "twin.macro"

export const GlobalStyles = css`
  ${emotionReset}
  * {
    ${tw`box-border`}
  }
  html,
  body {
    ${tw`font-normal bg-light text-copy font-text text-12 md:text-16`}

  }
  a {
    ${tw`text-copy`}
  }
  ul {
    ${tw`ml-1-2`}
  }
  li {
    color: currentColor;
    ${tw`list-disc`}
  }
  b {
    ${tw`font-extrabold text-primary`}
  }
`
