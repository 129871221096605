import loadable from '@loadable/component'
import { FC, useContext, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { PageStoryblok } from '../../@types/storyblok'
import { useNavigation } from '../../hooks/useNavigation'
import { HeaderContext } from '../../providers/header'
import { tailwind } from '../../styles/style-map'
import { Desktop, Mobile } from '../../styles/utility'
import { Link } from '../link/link'
import { Logo } from '../logo/logo'
const HamburgerMenu = loadable(() => import(/* webpackChunkName: "react-hamburger-menu" */ 'react-hamburger-menu'))
const ScrollLock = loadable(() => import(/* webpackChunkName: "react-scrolllock" */ 'react-scrolllock'))

export type HeaderProps = {
  hasHero?: boolean
  transparent: boolean
  hamburgerMenuColour?: 'light' | 'copy' | 'accent' | 'primary' | 'secondary' | 'tertiary'
}

const HeaderContainer = styled.header<Pick<HeaderProps, 'transparent' | 'hasHero'>>`
  ${({ hasHero }) => (!hasHero ? tw`min-h-19 md:min-h-22` : null)}
  ${({ transparent }) => (transparent ? tw`bg-light` : null)}
`
const LogoContainer = tw.div`
  absolute z-10 left-4 top-4
`
const HeaderHamburgerContainer = tw.div`
  fixed z-100 right-4 top-4
`
const HeaderNav = tw.nav`
  w-1/3
`
const HeaderNavContainer = styled.div<{ isOpen: boolean }>`
  ${tw`fixed top-0 right-0 flex flex-col w-full h-full transition-all bg-primary ease-cubic duration-slow z-80 sm:w-50`}
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: translate(${({ isOpen }) => (isOpen ? '0' : '30rem')});
`
const HeaderNavList = tw.ul`
  vr-normal-24 h-full flex flex-col px-6 py-12
`
const HeaderNavListItem = tw.li`
  list-none
`
const HeaderNavListItemLink = styled(Link)`
  ${tw`uppercase text-accent hover:text-secondary`}
`
const HeaderNavListItemButton = styled.button`
  ${tw`p-0 uppercase border-0 cursor-pointer bg-primary text-24 text-accent hover:text-secondary`}
`
const HeaderNavListItemSubLink = styled(Link)`
  ${tw`pl-2 uppercase text-secondary hover:text-light`}
`

export const Header: FC<HeaderProps> = ({ transparent }: HeaderProps) => {
  const { header } = useNavigation()
  const [isOpen, setIsOpen] = useState(false)
  const [navOpen, setNavOpen] = useState<string | null>(null)
  const { hasHero, hamburgerMenuColours } = useContext(HeaderContext)

  const handleClick = () => {
    setIsOpen((prevState) => !prevState)
  }

  const handleNavClick = (option: string) => () => {
    setNavOpen((prevOption) => (prevOption === option ? null : option))
  }

  if (!header && !Array.isArray(header)) {
    return null
  }

  const { content } = header
  const { pages }: { pages: PageStoryblok[] } = JSON.parse(content)
  const mobileHamburgerMenuColour = isOpen
    ? tailwind.theme.colors.light
    : hamburgerMenuColours?.[0]
    ? tailwind.theme.colors[hamburgerMenuColours[0]]
    : tailwind.theme.colors.copy
  const desktopHamburgerMenuColour = isOpen
    ? tailwind.theme.colors.light
    : hamburgerMenuColours?.[1]
    ? tailwind.theme.colors[hamburgerMenuColours[1]]
    : tailwind.theme.colors.copy

  const treeMap: Record<string, boolean> = {}
  const tree = pages
    .map((page) => {
      const {
        content: { navigationParentFolder },
      } = page

      if (treeMap[navigationParentFolder]) {
        return null
      }

      if (navigationParentFolder && !treeMap[navigationParentFolder]) {
        treeMap[navigationParentFolder] = true

        return pages.filter(({ content: { navigationParentFolder: subFolder } }) => {
          return subFolder === navigationParentFolder
        })
      }

      return page
    })
    .filter((page) => !!page)

  return (
    <HeaderContainer hasHero={hasHero} transparent={transparent}>
      <HeaderNav>
        <LogoContainer>
          <Mobile>
            <Link to="/">
              <Logo id="mobile-logo" fill={hasHero ? 'light' : 'primary'} width={131} height={75} />
            </Link>
          </Mobile>
          <Desktop>
            <Link to="/">
              <Logo id="desktop-logo" fill={hasHero ? 'light' : 'primary'} width={263} height={150} />
            </Link>
          </Desktop>
        </LogoContainer>
        <HeaderHamburgerContainer>
          <Mobile>
            <HamburgerMenu
              color={mobileHamburgerMenuColour}
              isOpen={isOpen}
              menuClicked={handleClick}
              width={48}
              height={32}
            />
          </Mobile>
          <Desktop>
            <HamburgerMenu
              color={desktopHamburgerMenuColour}
              isOpen={isOpen}
              menuClicked={handleClick}
              width={48}
              height={32}
            />
          </Desktop>
        </HeaderHamburgerContainer>
        <ScrollLock isActive={isOpen} />
        <HeaderNavContainer isOpen={isOpen}>
          <HeaderNavList>
            {tree?.map((page, i) => {
              if (Array.isArray(page)) {
                const {
                  content: { navigationParentFolder },
                } = page[0]
                return (
                  <HeaderNavListItem key={i}>
                    <HeaderNavListItemButton onClick={handleNavClick(navigationParentFolder)}>
                      {navigationParentFolder}
                    </HeaderNavListItemButton>
                    {page.map((subPage) => {
                      const { full_slug, name, content } = subPage
                      if (navOpen === navigationParentFolder) {
                        return (
                          <HeaderNavListItem>
                            <HeaderNavListItem key={full_slug}>
                              <HeaderNavListItemSubLink to={`/${full_slug}`} onClick={handleClick}>
                                {content.navigationLabel || name}
                              </HeaderNavListItemSubLink>
                            </HeaderNavListItem>
                          </HeaderNavListItem>
                        )
                      }
                      return null
                    })}
                  </HeaderNavListItem>
                )
              }

              const { full_slug, name, content } = page
              return (
                <HeaderNavListItem key={full_slug}>
                  <HeaderNavListItemLink onClick={handleClick} to={`/${full_slug}`}>{content.navigationLabel || name}</HeaderNavListItemLink>
                </HeaderNavListItem>
              )
            })}
          </HeaderNavList>
        </HeaderNavContainer>
      </HeaderNav>
    </HeaderContainer>
  )
}
