import { graphql, useStaticQuery } from "gatsby"

export const useSettings = () => {
  const { footer } = useStaticQuery(graphql`
    query Settings {
      footer: allStoryblokEntry(filter: { field_component: { eq: "settings" } }) {
        edges {
          footer: node {
            copyright: field_copyright_string,
            email: field_email_string,
            address1: field_address1_string,
            address2: field_address2_string,
            postcode: field_postcode_string,
            city: field_city_string,
          }
        }
      }
    }
 `)

  return {
    footer: footer.edges[0]?.footer || {},
  }
}
