import { Link as GatsbyLink } from 'gatsby'
import { ReactNode } from 'react'
import tw, { styled } from 'twin.macro'
import { styles } from '../../styles/style-map'

export type LinkProps = {
  colour?: keyof typeof styles.colour
  bold?: boolean
  button?: boolean
  hoverColour?: keyof typeof styles.colour.hover
  focusColour?: keyof typeof styles.colour.focus
  underline?: boolean
  to: string
  children: ReactNode
  onClick?: () => void
}

const StyledLink = styled(GatsbyLink)<LinkProps>(({ bold, colour, hoverColour, focusColour, underline, button }) => [
  tw`transition-all ring-offset-2 ring-2 duration-slow`,
  colour ? styles.colour[colour] : styles.colour.light,
  hoverColour ? styles.colour.hover[hoverColour] : styles.colour.hover.light,
  focusColour ? styles.colour.focus[focusColour] : styles.colour.focus.light,
  underline ? tw`underline` : tw`no-underline`,
  bold ? tw`font-extrabold` : null,
  button ? tw`px-3 py-1 border border-solid border-primary` : null,
  button ? hoverColour ? styles.colour.border[hoverColour] : styles.colour.border.primary : null
])

export const Link = ({
  bold = false,
  colour = 'copy',
  hoverColour = 'secondary',
  focusColour = 'secondary',
  underline = true,
  button = false,
  ...rest
}: LinkProps) => (
  <StyledLink
    bold={bold ? bold : undefined}
    colour={colour}
    hoverColour={hoverColour}
    focusColour={focusColour}
    button={button ? button : undefined}
    {...rest}
  />
)
