import { Email } from "react-obfuscate-email"
import tw from "twin.macro"
import { PageStoryblok } from "../../@types/storyblok"
import { useNavigation } from "../../hooks/useNavigation"
import { useSettings } from "../../hooks/useSettings"
import { Link } from "../link/link"
import { Logo } from "../logo/logo"

const FooterContainer = tw.footer`
  vr-normal-16 flex flex-col md:flex-row w-full max-w-100 mx-auto p-4
`
const FooterWrapper = tw.div`
  bg-tertiary text-light
`
const FooterNavContainer = tw.nav`
  flex items-start order-1 pb-4 justify-end w-full md:order-2 md:pb-0 md:w-1/2 md:items-end md:border-l-copy md:border-l-2 md:border-solid
`
const FooterNavList = tw.ul`
  list-none ml-0 flex flex-col w-1/2 self-start md:pl-2
`
const FooterNavListItem = tw.li`
  flex w-full
`
const FooterNavListItemLink = tw(Link)`
  uppercase my-0-1 ring-light
`
const LogoContainer = tw.div`
  w-1/2 flex justify-end
`
const ContactContainer = tw.div`
  flex flex-col w-full items-start order-2 md:order-1 md:w-1/2
`
const ObfuscatedEmail = tw(Email)`
  no-underline transition-all text-light hover:text-secondary focus:text-secondary duration-slow
`
const Copyright = tw.small`
  mt-2
`
const Address = tw.address`
`
const AddressItem = tw.p`
  mb-0-5
`

export const Footer = () => {
  const { footer: settings } = useSettings()
  const { footer: navigation } = useNavigation()
  const { content } = navigation
  const { pages }: { pages: PageStoryblok[]} = JSON.parse(content)
  const { email, copyright, address1, address2, postcode, city } = settings

  return (
    <FooterWrapper>
      <FooterContainer>
        <ContactContainer>
          <Address>
            <AddressItem>
              <ObfuscatedEmail email={email}>{email}</ObfuscatedEmail>
            </AddressItem>
            <AddressItem>{address1}</AddressItem>
            <AddressItem>{address2}</AddressItem>
            <AddressItem>{city} {postcode}</AddressItem>
          </Address>
          <Copyright>{copyright}</Copyright>
        </ContactContainer>
        <FooterNavContainer>
          <FooterNavList>
            {pages?.map(({ full_slug, name }) => (
              <FooterNavListItem key={full_slug}>
                <FooterNavListItemLink underline={false} colour="accent" to={`/${full_slug}`}>{name}</FooterNavListItemLink>
              </FooterNavListItem>
            ))}
          </FooterNavList>
          <LogoContainer>
            <Link to="/">
              <Logo fill="light" width={200} height={80} />
            </Link>
          </LogoContainer>
        </FooterNavContainer>
      </FooterContainer>
    </FooterWrapper>
  )
}
