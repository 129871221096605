import { FC, SVGProps } from 'react'
import tailwind from '../../../tailwind.default'

export const Logo: FC<SVGProps<SVGSVGElement>> = ({ width = 80, height = 80, fill = 'primary', id = 'default' }) => {
  const fillColour = tailwind.theme.colors[fill]
  return (
    <svg width={width} height={height} viewBox="0 0 1206.45 698.2">
      <path
        fill={fillColour}
        d="M123.12 692.24 49.35 477.07h47.34l40.88 120.8 46.42-120.8h23.36l46.42 120.8 40.88-120.8h47.34l-73.77 215.17h-19.06l-53.5-138.94-53.49 138.94h-19.05zm233.71-215.17h136.79v36.89h-93.14v49.18h89.76v36.89h-89.76v55.33h94.37v36.89H356.83V477.07zm372.86 0h43.65v178.29h105.13v36.89H729.69V477.07zm133.04 0h146.93v36.89h-51.64v178.29h-43.65V513.96h-51.64v-36.89zm291 120.19h-85.46v94.98h-43.65V477.07h43.65v83.3h85.46v-83.3h43.65v215.17h-43.65v-94.98z"
      />
      <linearGradient id={`${id}-a`} x1="603.085" x2="609.652" y1="51.192" y2="786.729" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#faaf40" />
        <stop offset="1" stop-color="#ec1c24" />
      </linearGradient>
      <path
        fill={`url(#${id}-a)`}
        d="m710.56 692.24-95.29-215.17h-12.91l-95.29 215.17h203.49zM608.81 553.3l41.11 101.44H567.7l41.11-101.44z"
      />
      <path
        fill={fillColour}
        d="M356.83 5.96h52.87c67.32 0 114.66 39.35 114.66 107.59 0 68.24-47.34 107.59-114.66 107.59h-52.87V5.96zm43.65 177.67h9.22c45.19 0 72.85-26.74 72.85-70.09 0-43.34-27.67-70.08-72.85-70.08h-9.22v140.17z"
      />
      <linearGradient id={`${id}-b`} x1="607.291" x2="613.858" y1="51.154" y2="786.692" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#faaf40" />
        <stop offset="1" stop-color="#ec1c24" />
      </linearGradient>
      <path
        fill={`url(#${id}-b)`}
        d="M710.56 221.13 615.26 5.96h-12.91l-95.29 215.17h203.5zM608.81 82.19l41.11 101.44H567.7l41.11-101.44z"
      />
      <path
        fill={fillColour}
        d="M729.69 130.14 661.45 5.96h48.26l41.81 82.69 41.8-82.69h48.26l-68.24 124.19v90.99h-43.65v-91z"
      />
      <g>
        <path
          fill={fillColour}
          d="M239.01 365.7h-10.45v90.99h-43.65V241.51h67.93c41.81 0 72.24 22.75 72.24 62.09 0 28.59-15.98 48.26-40.58 57.18l57.48 95.91h-48.26l-54.71-90.99zm-10.45-37.5h20.6c21.21 0 32.28-7.99 32.28-24.59s-11.07-24.59-32.28-24.59h-20.6v49.18z"
        />
        <linearGradient id={`${id}-c`} x1="605.2" x2="611.767" y1="52.525" y2="788.062" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#faaf40" />
          <stop offset="1" stop-color="#ec1c24" />
        </linearGradient>
        <path
          fill={`url(#${id}-c)`}
          d="m710.56 456.69-95.29-215.17h-12.91l-95.29 215.17h203.49zM608.81 317.75l41.11 101.44H567.7l41.11-101.44z"
        />
        <path
          fill={fillColour}
          d="M356.83 241.51h136.79v36.89h-93.14v49.18h89.76v36.89h-89.76v55.33h94.37v36.89H356.83V241.51zm416.51 117.12v98.06h-43.65V241.51h43.65v97.75l75.31-97.75h47.34l-80.84 104.21 88.53 110.97h-51.64l-78.7-98.06zm-648.38-15.06c17.52-7.68 32.58-23.36 32.58-47.03 0-32.89-25.21-55.02-72.54-55.02H9.07v215.17h87.3c38.73 0 71.32-20.9 71.32-60.86 0-27.36-18.45-45.81-42.73-52.26zm-32.58 75.61H52.72V279.01H85c25.82 0 30.74 11.06 30.74 24.28s-4.92 24.28-30.74 24.28H73.53l-22.99 37.5h41.83c23.05 0 31.66 10.76 31.66 27.05s-8.6 27.06-31.65 27.06z"
        />
      </g>
    </svg>
  )
}

export const LockUp: FC<SVGProps<SVGSVGElement>> = ({ width = 210, height = 699, id = 'lock-up' }) => (
  <svg width={width} height={height} viewBox="0 0 210 699" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient y2="3.62844" y1="0.21004" x2="0.52481" x1="0.49254" id={`${id}-b`}>
        <stop stop-color="#faaf40" offset="0" />
        <stop stop-color="#ec1c24" offset="1" />
      </linearGradient>
      <linearGradient y2="2.54005" y1="-0.87835" x2="0.51451" x1="0.48223" id={`${id}-c`}>
        <stop stop-color="#faaf40" offset="0" />
        <stop stop-color="#ec1c24" offset="1" />
      </linearGradient>
      <linearGradient y2="1.43914" y1="-1.97926" x2="0.50411" x1="0.47184" id={`${id}-a`}>
        <stop stop-color="#faaf40" offset="0" />
        <stop stop-color="#ec1c24" offset="1" />
      </linearGradient>
    </defs>
    <g>
      <path
        d="m206.745,692.24002l-95.29,-215.17l-12.91,0l-95.29,215.17l203.49,0zm-101.75,-138.94l41.11,101.44l-82.22,0l41.11,-101.44z"
        fill={`url(#${id}-a)`}
      />
      <path
        d="m206.75,221.13002l-95.3,-215.17l-12.91,0l-95.29,215.17l203.5,0zm-101.75,-138.94l41.11,101.44l-82.22,0l41.11,-101.44z"
        fill={`url(#${id}-b)`}
      />
      <path
        d="m206.745,456.69002l-95.29,-215.17l-12.91,0l-95.29,215.17l203.49,0zm-101.75,-138.94l41.11,101.44l-82.22,0l41.11,-101.44z"
        fill={`url(#${id}-c)`}
      />
    </g>
  </svg>
)
